
import { defineComponent, toRefs, Ref } from 'vue';
import {mapGetters} from 'vuex';

import {useVideoDetail} from '@/compositions/videos';

import BreadCrumbs from '@/components/widgets/bread-crumbs.vue';

export default defineComponent({
    name: 'video-detail',

    props: {
        id: Number
    },

    components: {
        BreadCrumbs
    },

    setup: (props) => {

        const {id} = toRefs(props);

        return useVideoDetail(id as Ref<number>);
    },

    computed: {
        ...mapGetters('training', ['trainingAccess'])
    },

    watch: {
        hasAccess: function (value) {
            if (!value) {
                this.$store.commit('app/H5-QRCODE-MODAL', true);
            }
        }
    }
});
