<template>
    <div class="container news-page">

        <bread-crumbs />

        <div class="com-detail-article com-part">
            <h1 class="title">{{video.title}}</h1>

            <div class="article" v-html="video.content" />

            <!-- <div class="frame-l-r clearfix">
                <div class="left">
                    <div class="com-news-dianping-list com-part">
                        <h3 class="part-title">视频点评</h3>
                        <ul>
                            <li class="clearfix">
                                <div class="item-left">
                                    <div class="img-w">
                                        <img src="http://avatar.user.mucang.cn/user-avatar/2021/03/02/19/2e253c75bebb4b028900e1d3c0327e52.jpg?_platform=android!100x100">
                                    </div>
                                </div>
                                <div class="item-right">
                                    <div class="content-top clearfix">
                                        <span class="author-name left">需要勇气</span>
                                    </div>
                                    <p class="content">接个好运，希望明天也一把过</p>
                                    <div class="content-bottom clearfix">
                                        <span class="date left">2021-04-12 21:18</span>
                                        <a class="huifu right" title="回复"></a>
                                        <a class="zan right" data title="点赞">0</a>
                                    </div>
                                </div>
                            </li>
                            <li class="clearfix">
                                <div class="item-left">
                                    <div class="img-w">
                                        <img src="http://avatar.user.mucang.cn/user-avatar/2020/09/19/16/a9ee104f3460497ca1a5145b7f8292d1.jpg!100x100">
                                    </div>
                                </div>
                                <div class="item-right">
                                    <div class="content-top clearfix">
                                        <span class="author-name left">胡再云</span>
                                    </div>
                                    <p class="content quote">回复 徒瀛T02：我侧方入库没问题了，就是出来，总是把握不好车身与边线的距离，要么距离超过一米，要么压线，烦死了</p>
                                    <p class="content">你要对点出来</p>
                                    <div class="content-bottom clearfix">
                                        <span class="date left">2020-09-01 17:10</span>
                                        <a class="huifu right" title="回复"></a>
                                        <a class="zan right" data title="点赞">1</a>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>

                    <div class="dianping-more">加载更多</div>

                    <div class="com-vedio-list1 com-part">
                        <h3 class="part-title">大家都在看<a class="more">更多</a></h3>
                        <ul class="clearfix">
                            <li>
                                <a class="img-w">
                                    <img alt="倒车入库" src="" />
                                    <i class="play-icon"></i><span class="duration">04:55</span>
                                </a>
                                <a class="name">倒车入库</a>
                                <div class="address clearfix">
                                    <span class="left">来自:小车科二学车视频分享</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="right">

                </div>
            </div> -->

        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, toRefs, Ref } from 'vue';
import {mapGetters} from 'vuex';

import {useVideoDetail} from '@/compositions/videos';

import BreadCrumbs from '@/components/widgets/bread-crumbs.vue';

export default defineComponent({
    name: 'video-detail',

    props: {
        id: Number
    },

    components: {
        BreadCrumbs
    },

    setup: (props) => {

        const {id} = toRefs(props);

        return useVideoDetail(id as Ref<number>);
    },

    computed: {
        ...mapGetters('training', ['trainingAccess'])
    },

    watch: {
        hasAccess: function (value) {
            if (!value) {
                this.$store.commit('app/H5-QRCODE-MODAL', true);
            }
        }
    }
});
</script>

<style lang="scss" scoped>

</style>